import { DeviceThing } from './api'
import Iot, { ListThingsResponse } from 'aws-sdk/clients/iot'
import { IotData } from 'aws-sdk'

export async function fetchIoTDevices(awsInstanceIot: Iot, awsInstanceIotData: IotData): Promise<DeviceThing[]> {
    try {
        const data: ListThingsResponse = await new Promise((resolve, reject) => {
            awsInstanceIot.listThings({}, (err: any, data: any) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(data)
                }
            })
        })

        const newDeviceThings: DeviceThing[] = []

        await Promise.all(
            (data.things || []).map(async (fetchedThing) => {
                const newDeviceThing: DeviceThing = {
                    thingArn: fetchedThing.thingArn,
                    thingName: fetchedThing.thingName,
                    thingTypeName: fetchedThing.thingTypeName,
                    version: fetchedThing.version,
                    instance: null,
                    lastUpdate: 0,
                    lastUpdateString: '',
                    datasyncd: {
                        sync: {
                            syncInterval: null,
                            monthsBack: null,
                            storageDir: null,
                            dbPath: null,
                            fetchLimit: null,
                        },
                    },
                    connman: {
                        ethernet: {
                            ipAddress: null,
                            macAddress: null,
                            dhcp: false,
                            ethernet: false,
                        },
                    },
                    vend: {
                        lang: {
                            language: '',
                            translation: false,
                        },
                        session: {
                            enableUserLogin: false,
                            enableUserAuth: false,
                            authUserInput: false,
                            userDefaultView: '',
                            showInfoAfterLogin: false,
                            shortInactivityTimeout: null,
                            longInactivityTimeout: null,
                            switchUserLanguage: false,
                            enableUserAutoLogin: false,
                            showContinuationQuestion: false,
                            continuationQuestionTimeout: null,
                        },
                        loading: {
                            hideLoadingFlaps: false,
                            hideLoadingGroup: false,
                            hideLoadingCell: false,
                            setCellSchema: false,
                            showOnlyAssignedProducts: false,
                            preventLoadingCells: false,
                            doNotUseDefined: false,
                            showOnlyAssignedProductItems: false,
                            showTreeStructure: false,
                            showOnlyAssignedToUser: false,
                            showZeroProd: false,
                        },
                        distribution: {
                            showZeroProd: false,
                            productFilterLevel: '',
                            showProdCard: false,
                            showAllCostCenters: false,
                            distributionWithCost: false,
                            forcePartialCollection: false,
                            forceUnlimitedAccess: false,
                            distributionWithBasket: false,
                            basketSize: null,
                            showLastDistributions: '',
                            userProductLimit: false,
                            showAvailableQuantity: false,
                            showContinuationQuestion: false,
                            continuationQuestionTimeout: null,
                            showTreeStructure: false,
                            showOnlyAssigned: false,
                            switchFilters: false,
                            flapCloseTime: null,
                        },
                        return: {
                            returnsEnabled: false,
                            allowReturnsToCells: false,
                            allowReturnsToLargerCells: false,
                            canReturnOnlyService: false,
                            canReturnOnlyRentable: false,
                            forcePartialReturn: false,
                        },
                        cells: {
                            cellsLabeling: '',
                            canReturnService: false,
                            skipSelectionLabel: false,
                        },
                        products: {
                            productItemsEnabled: false,
                            detachItem: false,
                            itemsSingleUse: false,
                            skipSelection: false,
                            showOnlyAssigned: false,
                        },
                        distributionRequest: {
                            distributionRequestEnabled: false,
                            requestValidDays: null,
                            enableOwnManagement: false,
                            filterLocalRequests: false,
                            userAuthRealization: false,
                            autoAcceptEnabled: false,
                            autoAcceptInterval: null,
                        },
                        integration: {
                            vsdEnable: false,
                            pipeLockPath: '',
                            pipeUnlockPath: '',
                            lockFilesPath: '',
                        },
                        frontend: {
                            idleScreenType: '',
                            slideshowPeriod: null,
                            slidesPath: '',
                            hideInactiveMenu: false,
                            productListColumn: '',
                            productCatalogColumn: '',
                            productCatalogRow: '',
                            transactionsListColumn: null,
                            transactionsListRow: null,
                            usersListColumn: null,
                            usersListRow: null,
                            showUserNames: false,
                            patternFilterDelay: null,
                            showCacheUpdate: false,
                            hideProductCatalog: false,
                            imagesPath: '',
                            showFpsCounter: false,
                        },
                        backend: {
                            noCanInterface: false,
                            cellAccessTimeLimit: null,
                            cellOpeningTimeLimit: null,
                            checkBeforeClosingCells: false,
                            lockOnIllegalOpen: false,
                            logNewTransactions: false,
                            flexEjectionTimeout: null,
                        },
                        other: {
                            cellReplenishment: false,
                            inventoryTransfer: false,
                        },
                    },
                }

                try {
                    const datasyncd = await fetchDeviceShadow(newDeviceThing.thingName!, 'datasyncd', awsInstanceIotData)

                    const connman = await fetchDeviceShadow(newDeviceThing.thingName!, 'connman', awsInstanceIotData)

                    const vend = await fetchDeviceShadow(newDeviceThing.thingName!, 'vend', awsInstanceIotData)

                    if (datasyncd) {
                        let deviceShadowState = datasyncd.state.desired
                        let deviceShadowMetadata = datasyncd.metadata.reported

                        const keys = Object.values(datasyncdMapping)
                        newDeviceThing.lastUpdate = getLatestTimestamp(deviceShadowMetadata, keys)

                        newDeviceThing.instance = deviceShadowState.RESTAPI_HOST

                        Object.keys(datasyncdMapping).forEach((key) => {
                            setNestedValue(newDeviceThing, key, deviceShadowState[datasyncdMapping[key]])
                        })
                    }

                    if (connman) {
                        let connmanState = connman.state.desired
                        let connmanMetadata = connman.metadata.reported

                        const connmanKeys = Object.values(connmanMapping)
                        newDeviceThing.lastUpdate = Math.max(newDeviceThing.lastUpdate, getLatestTimestamp(connmanMetadata, connmanKeys))

                        Object.keys(connmanMapping).forEach((key) => {
                            setNestedValue(newDeviceThing, key, connmanState[connmanMapping[key]])
                        })
                    }

                    if (vend) {
                        let vendState = vend.state.desired
                        let vendMetadata = vend.metadata.reported

                        const vendKeys = Object.values(vendMapping)
                        newDeviceThing.lastUpdate = Math.max(newDeviceThing.lastUpdate, getLatestTimestamp(vendMetadata, vendKeys))

                        Object.keys(vendMapping).forEach((key) => {
                            setNestedValue(newDeviceThing, key, vendState[vendMapping[key]])
                        })
                    }
                    newDeviceThing.lastUpdateString = formatTimestamp(newDeviceThing.lastUpdate)
                    newDeviceThings.push(newDeviceThing)
                } catch (error) {
                    // console.error(
                    // 	`Error fetching device shadow for ${newDeviceThing.thingName}:`,
                    // 	error
                    // );
                }
            })
        )

        return newDeviceThings
    } catch (error) {
        console.error('Error fetching IoT devices:', error)
        throw error
    }
}

export const fetchDeviceShadow = async (thingName: string, shadowName: string, awsInstanceIotData: IotData): Promise<any | null> => {
    if (!thingName || !shadowName) return null

    const params = {
        thingName: thingName,
        shadowName: shadowName,
    }

    try {
        const data = await awsInstanceIotData.getThingShadow(params).promise()
        const result = JSON.parse(data.payload as string) as any
        // console.log(`Device Shadow for ${thingName}:`, result);
        return result
    } catch (err) {
        // console.log("error");
        return null
    }
}

export const updateDeviceShadow = async (deviceThing: DeviceThing, shadowName: string, awsInstanceIotData: IotData): Promise<any | null> => {
    if (!deviceThing.thingName || !shadowName) return null
    let currentPayload = {}

    switch (shadowName) {
        case 'datasyncd':
            currentPayload = createPayload(reversedDatasyncdMapping, deviceThing)
            break
        case 'connman':
            currentPayload = createPayload(reversedConnmanMapping, deviceThing)
            break
        case 'vend':
            currentPayload = createPayload(reversedVendMapping, deviceThing)
            break
        default:
            currentPayload = {}
            break
    }

    const params = {
        thingName: deviceThing.thingName,
        shadowName: shadowName,
        payload: currentPayload,
    }

    try {
        await awsInstanceIotData.updateThingShadow(params).promise()
        return { success: true }
    } catch (err) {
        console.error('Error updating device shadow:', err)
        return null
    }
}

const setNestedValue = (obj: any, path: string, value: any) => {
    const keys = path.split('.')
    let current = obj
    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {}
        }
        current = current[keys[i]]
    }
    current[keys[keys.length - 1]] = value
}

const createPayload = (mapping: { [key: string]: string }, deviceThing: DeviceThing) => {
    const desiredState: { [key: string]: any } = {}
    Object.keys(mapping).forEach((key) => {
        const path = mapping[key]
        const value = getNestedValue(deviceThing, path)
        if (value !== undefined) {
            desiredState[key] = value
        }
    })
    return JSON.stringify({ state: { desired: desiredState } })
}

function getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

const getLatestTimestamp = (metadata: { [key: string]: any }, keys: string[]): number => {
    return keys.reduce((latest, key) => {
        const timestamp = metadata[key]?.timestamp || 0
        return Math.max(latest, timestamp)
    }, 0)
}

function formatTimestamp(timestamp: number): string {
    if (timestamp === 0) return ''
    const date = new Date(timestamp * 1000)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

const reverseKeyValuePairs = (obj: { [key: string]: string }): { [key: string]: string } => {
    const reversedObj: { [key: string]: string } = {}
    Object.entries(obj).forEach(([key, value]) => {
        reversedObj[value] = key
    })
    return reversedObj
}

const datasyncdMapping: { [key: string]: string } = {
    'datasyncd.sync.syncInterval': 'SYNCD_INTERVAL_SEC',
    'datasyncd.sync.monthsBack': 'SYNCD_MONTHSBACK',
    'datasyncd.sync.storageDir': 'SYNCD_STORAGEDIR',
    'datasyncd.sync.dbPath': 'DB_SQLITE_PATH',
    'datasyncd.sync.fetchLimit': 'RESTAPI_FETCH_LIMIT',
}

const connmanMapping: { [key: string]: string } = {
    'connman.ethernet.ipAddress': 'IPv4_address',
    'connman.ethernet.macAddress': 'MAC',
}

const vendMapping: { [key: string]: string } = {
    'vend.lang.language': 'LANGUAGE',
    'vend.lang.translation': 'TRANSLATION_ENFORCE_UPPER_CASE',
    'vend.session.enableUserAuth': 'UI_ENABLE_USER_AUTH_WITHOUT_PIN',
    'vend.session.authUserInput': 'UI_AUTH_USER_INPUT_TEXT_HIDDEN',
    'vend.session.userDefaultView': 'UI_US_DEF_VIEW',
    'vend.session.showInfoAfterLogin': 'UI_SHOW_INFO_AFTER_LOGIN',
    'vend.session.shortInactivityTimeout': 'UI_SHORT_INACTIVITY_TIMEOUT',
    'vend.session.longInactivityTimeout': 'UI_LONG_INACTIVITY_TIMEOUT',
    'vend.session.switchUserLanguage': 'UI_SWITCH_USER_LANGUAGE',
    'vend.session.enableUserAutoLogin': 'UI_ENABLE_USER_AUTO_LOGIN_BASED_ON_SESSION_FILE',
    'vend.session.showContinuationQuestion': 'UI_SHOW_AFTER_SUCCESSFUL_DISTRIBUTION_CONTINUATION_QUESTION',
    'vend.session.continuationQuestionTimeout': 'UI_AFTER_SUCCESSFUL_DISTRIBUTION_CONTINUATION_QUESTION_TIMEOUT',
    'vend.loading.hideLoadingFlaps': 'UI_HIDE_LOADING_THROUGH_FLAPS',
    'vend.loading.hideLoadingGroup': 'UI_HIDE_LOADING_GROUP_OF_PRODUCTS',
    'vend.loading.hideLoadingCell': 'UI_HIDE_LOADING_WITH_CELL_SCANNER',
    'vend.loading.setCellSchema': 'VM_SET_CELL_SCHEMA_WHILE_LOADING',
    'vend.loading.showOnlyAssignedProducts': 'UI_SHOW_ONLY_ASSIGNED_PRODUCTS_WHILE_LOADING',
    'vend.loading.preventLoadingCells': 'VM_PREVENT_LOADING_CELLS_WITH_SCHEMA',
    'vend.loading.doNotUseDefined': 'UI_DO_NOT_USE_DEFINED_MULTIPACKS',
    'vend.loading.showOnlyAssignedProductItems': 'UI_SHOW_ONLY_ASSIGNED_PRODUCT_ITEMS_WHILE_LOADING',
    'vend.loading.showTreeStructure': 'UI_SHOW_TREE_STRUCTURE_ON_LOADING',
    'vend.loading.showOnlyAssignedToUser': 'UI_SHOW_ONLY_ASSIGNED_TO_USER_ON_LOADING',
    'vend.loading.showZeroProd': 'UI_SHOW_ZERO_PROD_ON_LOADING',
    'vend.distribution.showZeroProd': 'UI_SHOW_ZERO_PROD',
    'vend.distribution.productFilterLevel': 'UI_PRODUCT_FILTER_LEVEL',
    'vend.distribution.showProdCard': 'UI_SHOW_PROD_CARD',
    'vend.distribution.showAllCostCenters': 'UI_SHOW_ALL_COST_CENTERS',
    'vend.distribution.distributionWithCost': 'VM_DISTRIBUTION_WITH_COST_CENTERS_SETS',
    'vend.distribution.forcePartialCollection': 'UI_FORCE_PARTIAL_COLLECTION',
    'vend.distribution.forceUnlimitedAccess': 'UI_FORCE_UNLIMITED_ACCESS_PROFILE',
    'vend.distribution.distributionWithBasket': 'UI_DISTRIBUTION_WITH_BASKET',
    'vend.distribution.basketSize': 'UI_BASKET_SIZE',
    'vend.distribution.showLastDistributions': 'UI_SHOW_LAST_DISTRIBUTIONS_OF_PRODUCT',
    'vend.distribution.userProductLimit': 'UI_USER_PRODUCT_LIMIT_WITHOUT_RETURNS',
    'vend.distribution.showAvailableQuantity': 'UI_SHOW_AVAILABLE_QUANTITY_IN_PRODUCT_CATALOG_WHILE_DISTRIBUTION',
    'vend.distribution.showTreeStructure': 'UI_SHOW_TREE_STRUCTURE_ON_DISTRIBUTION',
    'vend.distribution.showOnlyAssigned': 'UI_SHOW_ONLY_ASSIGNED_TO_DP_ON_DISTRIBUTION',
    'vend.distribution.switchFilters': 'UI_SWITCHING_FILTERS_ON_DISTRIBUTION',
    'vend.distribution.flapCloseTime': 'VM_CAN_WAIT_FLAP_CLOSE_MS',
    'vend.return.returnsEnabled': 'VM_RETURNS_ENABLED',
    'vend.return.allowReturnsToCells': 'VM_ALLOW_RETURNS_TO_CELLS_WITH_SCHEMA_WITH_DIFFERENT_SIZE',
    'vend.return.allowReturnsToLargerCells': 'VM_ALLOW_RETURNS_TO_LARGER_CELLS',
    'vend.return.canReturnOnlyService': 'UI_CAN_RETURN_ONLY_SERVICE_REQUIRED',
    'vend.return.canReturnOnlyRentable': 'UI_CAN_RETURN_ONLY_RENTABLE',
    'vend.return.forcePartialReturn': 'UI_FORCE_PARTIAL_RETURN',
    'vend.cells.cellsLabeling': 'VM_CELLS_LABELING',
    'vend.cells.skipSelectionLabel': 'UI_SKIP_SELECTION_FOR_SOLE_LABEL',
    'vend.products.productItemsEnabled': 'VM_PRODUCT_ITEMS_ENABLED',
    'vend.products.detachItem': 'VM_DETACH_PRODUCT_ITEM_AFTER_DISTRIBUTION',
    'vend.products.itemsSingleUse': 'VM_PRODUCT_ITEMS_SINGLE_USE',
    'vend.products.skipSelection': 'UI_SKIP_SELECTION_FOR_SOLE_ITEM',
    'vend.distributionRequest.distributionRequestEnabled': 'VM_DISTRIBUTION_REQUEST_ENABLED',
    'vend.distributionRequest.requestValidDays': 'VM_DISTRIBUTION_REQUEST_VALID_DAYS',
    'vend.distributionRequest.enableOwnManagement': 'VM_ENABLE_OWN_DR_MANAGEMENT',
    'vend.distributionRequest.filterLocalRequests': 'UI_FILTER_LOCAL_DISTRIBUTION_REQUESTS',
    'vend.distributionRequest.userAuthRealization': 'UI_USER_AUTH_AT_DR_REALIZATION',
    'vend.distributionRequest.autoAcceptEnabled': 'VM_DR_AUTO_ACCEPT_ENABLED',
    'vend.distributionRequest.autoAcceptInterval': 'VM_DR_AUTO_ACCEPT_INTERVAL',
    'vend.integration.vsdEnable': 'VSD_ENABLE',
    'vend.integration.pipeLockPath': 'VSD_PIPE_LOCK_PATH',
    'vend.integration.pipeUnlockPath': 'VSD_PIPE_UNLOCK_PATH',
    'vend.integration.lockFilesPath': 'VSD_LOCK_FILES_PATH',
    'vend.frontend.idleScreenType': 'UI_IDLE_SCR_TYPE',
    'vend.frontend.slideshowPeriod': 'UI_IDLE_SCR_SLIDE_PERIOD',
    'vend.frontend.slidesPath': 'UI_SLIDES_PATH',
    'vend.frontend.hideInactiveMenu': 'UI_HIDE_INACTIVE_MENU_ITEMS',
    'vend.frontend.productListColumn': 'UI_PRODLIST_COLNUM',
    'vend.frontend.productCatalogColumn': 'UI_PRODCATALOG_COLNUM',
    'vend.frontend.productCatalogRow': 'UI_PRODCATALOG_ROWNUM',
    'vend.frontend.transactionsListColumn': 'UI_TRANSACTIONS_LIST_COLNUM',
    'vend.frontend.transactionsListRow': 'UI_TRANSACTIONS_LIST_ROWNUM',
    'vend.frontend.usersListColumn': 'UI_USERS_LIST_COLNUM',
    'vend.frontend.usersListRow': 'UI_USERS_LIST_ROWNUM',
    'vend.frontend.showUserNames': 'UI_SHOW_USER_NAMES_ON_USERS_LIST',
    'vend.frontend.patternFilterDelay': 'UI_PATTERN_FILTER_DELAY',
    'vend.frontend.showCacheUpdate': 'UI_SHOW_DS_CACHE_UPDATE_PENDING',
    'vend.frontend.hideProductCatalog': 'UI_HIDE_PRODUCT_CATALOG_CONFIG_WIDGET',
    'vend.frontend.imagesPath': 'UI_IMAGES_PATH',
    'vend.frontend.showFpsCounter': 'UI_SHOW_FPS_COUNTER',
    'vend.backend.noCanInterface': 'VM_NO_CAN_IFACE',
    'vend.backend.cellAccessTimeLimit': 'VM_US_INACT_TOUT',
    'vend.backend.cellOpeningTimeLimit': 'VM_US_CELL_OPENED_TOUT',
    'vend.backend.lockOnIllegalOpen': 'VM_L40_LOCK_ON_ILLEGAL_OPEN_DISABLED',
    'vend.backend.logNewTransactions': 'VM_LOG_NEW_TRANSACTION',
    'vend.backend.flexEjectionTimeout': 'VM_FLEX_EJECTION_TIMEOUT',
    'vend.other.cellReplenishment': 'VM_CELLS_REPLENISHMENT',
    'vend.other.inventoryTransfer': 'VM_INVENTORY_TRANSFER_ENABLED',
}

const reversedDatasyncdMapping = reverseKeyValuePairs(datasyncdMapping)

const reversedConnmanMapping = reverseKeyValuePairs(connmanMapping)

const reversedVendMapping = reverseKeyValuePairs(vendMapping)
